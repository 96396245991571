import axios from "axios";

import {
  AuthorizeRequest,
  GuestRequest,
  LookupShopperUserRequest,
  LookupUserFullOptions,
  LookupUserFullRequest,
  LookupUserRequest,
  ReAuthorizeRequest,
  VerifyRequest,
} from "@models/api/apiRequests";
import { Claims } from "@models/claims";
import {
  ApiResponse,
  AuthorizeResponse,
  GuestResponse,
  LookupUserResponse,
  ShoppersLookupResponse,
  VerifyResponse,
} from "@models/api/apiResponses";
import axiosRetry from "@services/utils/axiosRetry";
import interceptWithErrorLogs from "@services/utils/interceptWithErrorLogs";
import interceptWithFingerprint from "@services/utils/interceptWithFingerprint";
import inMemoryStorage, { IN_MEMORY_STORAGE_KEYS } from "@utils/inMemoryStorage";
import getEnv from "@utils/getEnv";

import { addSentryInterceptors } from "./utils/interceptSentry";
import interceptWithSkipifyHeaders from "./utils/interceptWithSkipifyHeaders";
import { datadogLogs } from "@datadog/browser-logs";

// TODO: move to configuration
const BASE_URL = getEnv().AUTH_ROOT || `${getEnv().API_ROOT}/v3/auth-service`;

const API_MODULE = "shoppers/v1";

const LOOKUP_ENDPOINT = "/lookup_user";
const LOOKUP_FULL_ENDPOINT = "/lookup_user_full";
const LOOKUP_BY_FINGERPRINT_ENDPOINT = "/lookup_by_fingerprint";
const LOOKUP_SHOPPERS_ENDPOINT = "/identity-cloud/lookup";
const ACCEPT_ENDPOINT = "/challenge/accept";
const REACCEPT_ENDPOINT = "/challenge/reaccept";
const COMPLETE_ENDPOINT = "/challenge/complete";

const authApi = axios.create({
  baseURL: `${BASE_URL}/${API_MODULE}`,
  withCredentials: true,
});

const baseAuthApi = axios.create({
  baseURL: `${BASE_URL}/v1`,
  withCredentials: true,
});

addSentryInterceptors(authApi);
authApi.interceptors.response.use(undefined, interceptWithErrorLogs);
authApi.interceptors.request.use(interceptWithFingerprint);
authApi.interceptors.request.use(interceptWithSkipifyHeaders);

axiosRetry(authApi);

//We generate cache for merchants that overuse the lookupUser functionality
//endpoint so that it we don't make unnecessary calls to the backend
const CACHE_TTL = 1000;
type LookupType = "full" | "";

// Central cache object
const lookupCache: Record<string, { data: ApiResponse<LookupUserResponse>; timestamp: number }> = {};

// Function to generate cache key
function generateCacheKey(
  lookupType: LookupType,
  email?: string,
  phone?: string,
  merchantId?: string,
  transactionId?: string,
  skipCardLinking?: boolean,
  usePrefilledPhone?: boolean,
): string {
  return `${lookupType}:${merchantId || ""}:${email || ""}:${phone || ""}:${transactionId || ""}:${
    skipCardLinking || ""
  }:${usePrefilledPhone || ""}`;
}

function isCacheValid(cacheEntry: { data: ApiResponse<LookupUserResponse>; timestamp: number }): boolean {
  return Date.now() - cacheEntry.timestamp < CACHE_TTL;
}

function cacheResponse(cacheKey: string, response: ApiResponse<LookupUserResponse>) {
  lookupCache[cacheKey] = { data: response, timestamp: Date.now() };
}

function deleteCacheEntry(cacheKey: string) {
  delete lookupCache[cacheKey];
}

// Modified lookupUser function
export async function lookupUser(
  merchantId?: string | undefined,
  email?: string | undefined,
  phone?: string,
  transactionId?: string,
  abortController?: AbortController,
  skipCardLinking?: boolean,
  usePrefilledPhone?: boolean,
): Promise<ApiResponse<LookupUserResponse>> {
  const cacheKey = generateCacheKey("", email, phone, merchantId, transactionId, skipCardLinking, usePrefilledPhone);

  // Check cache
  const cachedEntry = lookupCache[cacheKey];
  if (cachedEntry) {
    if (isCacheValid(cachedEntry)) {
      return cachedEntry.data;
    } else {
      deleteCacheEntry(cacheKey); // Delete invalid cache entry
    }
  }

  const payload: LookupUserRequest = {
    email,
    phone,
    transactionId: transactionId,
    skipCardLinking,
    usePrefilledPhone,
  };

  const config = {
    signal: abortController ? abortController.signal : undefined,
    headers: {
      "Content-Type": "application/json",
      "x-merchant-id": merchantId ? merchantId : "",
    },
  };

  const response = await authApi.post<ApiResponse<LookupUserResponse>>(LOOKUP_ENDPOINT, payload, config);

  // Cache the result
  cacheResponse(cacheKey, response.data);

  return response.data;
}

export async function lookupUserFull({
  merchantId,
  email,
  phone,
  transactionId,
  skipCardLinking,
  abortController,
}: LookupUserFullOptions): Promise<ApiResponse<LookupUserResponse>> {
  const cacheKey = generateCacheKey("full", email, phone, merchantId, transactionId, skipCardLinking, false);

  // Check cache
  const cachedEntry = lookupCache[cacheKey];
  if (cachedEntry) {
    if (isCacheValid(cachedEntry)) {
      return cachedEntry.data;
    } else {
      deleteCacheEntry(cacheKey); // Delete invalid cache entry
    }
  }

  const payload: LookupUserFullRequest = {
    email,
    phone,
    transactionId,
    skipCardLinking,
  };

  const config = {
    signal: abortController ? abortController.signal : undefined,
    headers: {
      "Content-Type": "application/json",
      "x-merchant-id": merchantId ? merchantId : "",
    },
  };

  const response = await authApi.post<ApiResponse<LookupUserResponse>>(LOOKUP_FULL_ENDPOINT, payload, config);

  // Cache the result
  cacheResponse(cacheKey, response.data);

  return response.data;
}

export async function lookupByFingerprint(
  merchantId?: string | undefined,
  abortController?: AbortController,
): Promise<ApiResponse<LookupUserResponse>> {
  const config = {
    signal: abortController ? abortController.signal : undefined,
    headers: {
      "Content-Type": "application/json",
      "x-merchant-id": merchantId ? merchantId : "",
    },
  };

  const response = await authApi.post<ApiResponse<LookupUserResponse>>(LOOKUP_BY_FINGERPRINT_ENDPOINT, {}, config);
  return response.data;
}

export async function lookupShoppers(
  merchantId: string,
  options: LookupShopperUserRequest,
): Promise<ApiResponse<ShoppersLookupResponse>> {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-merchant-id": merchantId,
    },
  };

  if ("useDeviceId" in options && options.useDeviceId) {
    // useDeviceId lookup
    datadogLogs.logger.info("LookupShoppers with DeviceId", {
      merchantId,
    });
    return (
      await authApi.post<ApiResponse<ShoppersLookupResponse>>(LOOKUP_SHOPPERS_ENDPOINT, { useDeviceId: true }, config)
    ).data;
  }

  if ("email" in options && options.email) {
    // Email (and optional phone) lookup
    datadogLogs.logger.info("LookupShoppers with email/phone", {
      merchantId,
    });
    const payload: LookupShopperUserRequest = { email: options.email };
    if (options.phone) {
      payload.phone = options.phone;
    }
    return (await authApi.post<ApiResponse<ShoppersLookupResponse>>(LOOKUP_SHOPPERS_ENDPOINT, payload, config)).data;
  }

  throw new Error("Invalid lookup shopper request");
}

//see: https://skipify.atlassian.net/browse/PS-356
export async function authorize(transactionId: string): Promise<ApiResponse<AuthorizeResponse>> {
  const payload: AuthorizeRequest = {
    transactionId: transactionId,
  };

  const response = await authApi.post<ApiResponse<AuthorizeResponse>>(ACCEPT_ENDPOINT, payload, {
    headers: { "Content-Type": "application/json" },
  });

  return response.data;
}

export async function guestSignUp(email: string, phone?: string): Promise<ApiResponse<GuestResponse>> {
  const payload: GuestRequest = {
    email,
    phone,
  };
  const response = await authApi.post<ApiResponse<GuestResponse>>("/guest", payload, {
    headers: { "Content-Type": "application/json" },
  });
  inMemoryStorage.setItem(IN_MEMORY_STORAGE_KEYS.SESSION, response.data.data?.jwt);
  return response.data;
}

//see https://skipify.atlassian.net/browse/PS-413
//not sure what's the difference with /authorize right now
//you are supposed to use this to request resend code
export async function reAuthorize(
  transactionId: string,
  alternativeId?: string,
): Promise<ApiResponse<AuthorizeResponse>> {
  const payload: ReAuthorizeRequest = {
    transactionId: transactionId,
    alternativeDestinationId: alternativeId,
  };

  const response = await authApi.post<ApiResponse<AuthorizeResponse>>(REACCEPT_ENDPOINT, payload, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
}

// TODO: may need to be more generic
// see: https://skipify.atlassian.net/browse/PS-357
export async function verify(transactionId: string, otp: string, value?: string): Promise<ApiResponse<VerifyResponse>> {
  const payload: VerifyRequest = {
    transactionId: transactionId,
    otp,
    value,
  };
  const response = await authApi.post<ApiResponse<VerifyResponse>>(COMPLETE_ENDPOINT, payload, {
    headers: { "Content-Type": "application/json" },
  });

  // Put auth token into the in-memory storage
  const token = response.data.data?.jwt;
  inMemoryStorage.setItem(IN_MEMORY_STORAGE_KEYS.SESSION, token);

  return response.data;
}

export async function promoteGuest(): Promise<ApiResponse<LookupUserResponse>> {
  const token = inMemoryStorage.getItem(IN_MEMORY_STORAGE_KEYS.SESSION);
  const response = await authApi.post<ApiResponse<LookupUserResponse>>(
    "/guest/promote",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
}

export async function getClaims(): Promise<ApiResponse<Claims>> {
  const token = inMemoryStorage.getItem(IN_MEMORY_STORAGE_KEYS.SESSION);
  const response = await baseAuthApi.get<ApiResponse<Claims>>("/validate", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
